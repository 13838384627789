import axios from "axios"
import { camelizeKeys, decamelizeKeys } from "humps"

const api = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}`,
})

api.interceptors.request.use(
  function(config) {
    config.data = decamelizeKeys(config.data)
    config.headers = {
      ...config.headers,
      Authorization: process.env.GATSBY_API_KEY,
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function(response) {
    return camelizeKeys(response)
  },
  function(error) {
    return Promise.reject(error)
  }
)

export default api
