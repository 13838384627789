import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

/**
 * The classic button, in different colors, sizes, and states
 * @param {string} color string options: primary, success, info, danger, light, dark
 * @param {string} size string options: small, normal, medium, large
 * @param {string} variant string options: outlined, inverted
 * @param {string} type string options: submit, reset
 * @param {boolean} isLoading boolean
 * @param {boolean} isDisabled  boolean
 * @param {boolean} fullwidth  boolean
 * @param {} children
 */

const Button = ({
  color = "secondary",
  variant = "solid",
  size,
  children,
  type,
  isLoading,
  isDisabled,
  isFullwidth,
  className,
  ...props
}) => {
  const solidClassName =
    "flex w-auto py-1.5 !px-3 items-center gap-2 rounded-md bg-accent-lighter text-primary-darker"
  const outlinedClassName =
    "flex w-auto py-1.5 !px-3 items-center gap-2 rounded-md border-[1.5px] border-solid border-secondary text-primary-dark"

  const primaryClassName = "bg-brand text-white"
  const secondaryClassName = "bg-accent-lighter text-primary-darker"

  let variantClassName
  let colorClassName

  switch (color) {
    case "primary":
      variantClassName = primaryClassName
      break
    case "outlined":
      variantClassName = secondaryClassName
      break
    default:
      variantClassName = primaryClassName
      break
  }

  switch (variant) {
    case "solid":
      variantClassName = solidClassName
      break
    case "outlined":
      variantClassName = outlinedClassName
      break
    default:
      variantClassName = solidClassName
      break
  }

  return (
    <button
      type={type || "button"}
      className={classNames(
        `font-bold ${colorClassName} ${variantClassName} ${className}`,
        {
          "is-loading": isLoading,
        },
        { "w-full": isFullwidth }
      )}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default Button
