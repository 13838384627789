export const generatePrice = (medicine) => {
  if (!medicine.price_list) return (0).toFixed(2)
  let vatex = medicine.price_list.vatex_unit_price || 0
  let vat = medicine.price_list.vat || 0
  return parseFloat(vatex + vat).toFixed(2)
}

export const generateVatex = (medicine) => {
  if (!medicine.price_list) return (0).toFixed(2)
  let vatex = medicine.price_list.vatex_unit_price || 0
  return parseFloat(vatex).toFixed(2)
}

export const generateVat = (medicine) => {
  if (!medicine.price_list) return (0).toFixed(2)
  let vat = medicine.price_list.vat || 0
  return parseFloat(vat).toFixed(2)
}

export const accumulateVat = (medicines) => {
  let total = 0
  medicines.forEach((medicine) => {
    total += medicine.price_list.vat * medicine.qty
  })
  return total
}

export const accumulatePrice = (medicines) => {
  let total = 0
  medicines.forEach((medicine) => {
    total += medicine.qty * parseFloat(generatePrice(medicine))
  })
  return total
}

export const generateDeliveryFee = ({
  coupon,
  couponCode,
  province,
  addressType,
}) => {
  const MM_DELIVERY_FEE = 200.0
  const BESTLIFE_DELIVERY_FEE = 175.0
  const PROVINCIAL_DELIVERY_FEE = 500.0

  if (couponCode?.startsWith("MGQ") && addressType === "Office") return 0
  if (
    coupon?.websiteDescription?.includes(
      "Free quarterly coupons for MedGrocer.com worth PHP1,500"
    ) &&
    addressType === "Office"
  )
    return 0

  if (couponCode?.startsWith("MGMXC")) return 0

  if (couponCode?.includes("BESTLIFE")) return BESTLIFE_DELIVERY_FEE
  if (province && province !== "Metro Manila") return PROVINCIAL_DELIVERY_FEE
  else return MM_DELIVERY_FEE
}

export const checkIfHasRxRequired = (medicines) => {
  if (medicines.length === 0) return true
  return medicines.some((medicine) => medicine.rxRequired === true)
}

export const initializeMaxPaymentValue = (subTotal) => {
  const value = parseFloat(subTotal)
  return Math.ceil(value / 1000) * 1000
}
