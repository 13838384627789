import React from "react"
import classNames from "classnames"

import RxIndicator from "../../Elements/RxIndicator"

import styles from "../utils/cart.module.scss"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { generatePrice } from "../../Epharmacy/services/computations"

const MedicinesCardRow = ({ medicine, handleEditMedicine }) => (
  <li>
    <div className="columns is-mobile">
      <div className={classNames("column", styles["column"])}>
        <button
          onClick={() => handleEditMedicine(medicine)}
          className={classNames([
            styles["editButton"],
            "has-text-primary has-text-right",
          ])}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <div className="is-flex has-text-weight-bold">
          <span className="is-size-6">
            {medicine.drugName ||
              medicine?.productTitle.replace(/\([^)]*\)/g, "")}
          </span>
          {medicine.rxRequired && <RxIndicator />}
        </div>
      </div>
    </div>
    <div className="columns">
      <div className={classNames("column", styles["column"])}>
        {medicine.brand}
      </div>
      <div
        className={classNames(
          "column is-flex is-justify-content-end is-align-items-center",
          styles["column"]
        )}
      >
        <div className="is-size-6 pr-1">
          Php {(generatePrice(medicine) * 1).toFixed(2)}
        </div>
        <div className="is-size-6 tag">x {medicine.qty}</div>
      </div>
    </div>
    <div className="flex justify-end">
      <div className="is-size-6">
        Total:{" "}
        <span className="text-primary has-text-weight-bold">
          Php {(generatePrice(medicine) * medicine.qty).toFixed(2)}
        </span>
      </div>
    </div>
  </li>
)

export default MedicinesCardRow
