import React from "react"
import { generatePrice } from "../services/computations"

const TotalPriceInfo = ({ medicine, qty }) => (
  <p className="has-text-right">
    Total:{" "}
    <span className="has-text-primary has-text-weight-bold">
      Php {(generatePrice(medicine) * qty).toFixed(2)}
    </span>
  </p>
)

export default TotalPriceInfo
