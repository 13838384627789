import axios from "axios"

import {
  GATSBY_AIRTABLE_API_URL,
  GATSBY_AIRTABLE_API_KEY,
} from "gatsby-env-variables"

export const airtableApi = axios.create({
  baseURL: GATSBY_AIRTABLE_API_URL,
  headers: {
    Authorization: "Bearer " + GATSBY_AIRTABLE_API_KEY,
    "Content-Type": "application/json",
  },
})
