import api from "../../../services/api"
import axios from "axios"
import moment from "moment"
import lodash from "lodash"
import specialCoupons from "../utils/specialCoupons.json"
import { airtableApi } from "../../../services/airtable/airtableApi"

import {
  GATSBY_AIRTABLE_API_URL,
  GATSBY_AIRTABLE_API_KEY,
  GATSBY_COUPON_ENVIRONMENT,
  GATSBY_COUPONS_FIREBASE_FUNCTIONS,
} from "gatsby-env-variables"

function isProductIncluded(item, include, exclude) {
  if (!include) return false
  var isIncluded = false
  const findCategory = (singleCategory) => singleCategory === exclude[i].title
  for (var i = 0; i < include.length; i++) {
    switch (include[i].type) {
      case "all":
        isIncluded = true
        break
      case "classification":
        if (item.classification === include[i].title) isIncluded = true
        break
      case "category":
        if (item.category.find(findCategory)) isIncluded = true
        break
      case "product":
        if (item.productTitle === include[i].title) isIncluded = true
        break
      case "rxLevel":
        if (include[i].value.indexOf(item.rxLevel) >= 0) isIncluded = true
        break
      case "rxRequired":
        if (item.rxRequired === include[i].value) isIncluded = true
        break
      default:
        isIncluded = false
        break
    }
  }
  if (exclude) {
    for (i = 0; i < exclude.length; i++) {
      switch (exclude[i].type) {
        case "all":
          isIncluded = false
          break
        case "classification":
          if (item.classification === exclude[i].title) isIncluded = false
          break
        case "category":
          if (item.category.find(findCategory)) isIncluded = false
          break
        case "product":
          if (item.id === exclude[i].productId) isIncluded = false
          break
        case "rxRequired":
          if (item.rxRequired === exclude[i].value) isIncluded = false
          break
        default:
          isIncluded = false
          break
      }
    }
  }

  return isIncluded
}

export const getCoupon = async (couponCode, callback, errorCallback) => {
  try {
    let responseData = await axios.post(
      `${GATSBY_COUPONS_FIREBASE_FUNCTIONS}/get`,
      {
        coupon: couponCode,
        apiUrl: GATSBY_AIRTABLE_API_URL,
        apiKey: GATSBY_AIRTABLE_API_KEY,
        environment: GATSBY_COUPON_ENVIRONMENT,
      }
    )

    responseData = responseData?.data

    if (!responseData) {
      errorCallback()
      return
    }

    if (typeof responseData?.ProductInclusions !== "undefined")
      responseData["productInclusions"] = JSON.parse(
        responseData?.ProductInclusions
      )
    if (typeof responseData?.ProductExclusions !== "undefined")
      responseData["productExclusions"] = JSON.parse(
        responseData?.ProductExclusions
      )
    if (typeof responseData?.FreeProductInclusions !== "undefined")
      responseData["freeProductInclusions"] = JSON.parse(
        responseData?.FreeProductInclusions
      )

    responseData = lodash.mapKeys(responseData, (value, key) =>
      lodash.camelCase(key)
    )

    if (responseData.expirationDate) {
      const isCouponExpired =
        moment().diff(responseData.expirationDate, "minutes") > 0
      if (isCouponExpired)
        throw { message: "This coupon has expired", response: {} }
    }
    if (responseData.totalUseLimit === 0)
      throw { message: "This coupon has been used", response: {} }

    if (callback) callback(responseData)
  } catch (error) {
    const status = error?.response?.status
    if (errorCallback) errorCallback(status === 404 ? "" : error?.message)
  }
}

export const getCouponDiscountAmount = (coupon, medicines, deliveryFee) => {
  if (coupon?.error) return "0.00"
  if (!coupon) return "0.00"
  if (coupon?.maxAmount === 0) return "0.00"
  if (specialCoupons.includes(coupon.code)) {
    const amount = medicines.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        parseFloat(currentValue.price_list.vatex_unit_price) *
          parseInt(currentValue.qty) *
          0.2
      )
    }, 0)
    return parseFloat(amount).toFixed(2)
  } else {
    const { productInclusions, productExclusions } = coupon

    let sum = 0
    let discountAmount = 0
    let brandedDiscount = 0

    // if (coupon.includesConvenienceFee) sum += parseFloat(deliveryFee)

    let hasBrandedDiscount = productInclusions.find(
      (productInclusion) =>
        productInclusion?.type === "classification" &&
        productInclusion?.title?.includes("branded") &&
        !!productInclusion?.percentOff
    )

    medicines.forEach((medicine) => {
      if (
        isProductIncluded(medicine, productInclusions, productExclusions) &&
        medicine?.category !== "Medical Supply"
      ) {
        let hasProductDiscount = productInclusions.find(
          (productInclusion) =>
            productInclusion?.type === "product" &&
            productInclusion?.title === medicine.productTitle &&
            !!productInclusion?.percentOff
        )

        let currentDiscount =
          (parseFloat(
            parseFloat(medicine.price_list.vatex_unit_price).toFixed(2)
          ) +
            parseFloat(medicine.price_list.vat)) *
          parseFloat(medicine.qty)

        let isMedicineBranded =
          medicine?.classification?.toLowerCase()?.includes("branded") &&
          !!hasBrandedDiscount

        if (isMedicineBranded && !!medicine?.rxRequired) {
          brandedDiscount =
            currentDiscount * parseFloat(hasBrandedDiscount?.percentOff / 100)
          currentDiscount = 0
        } else if (isMedicineBranded && !medicine?.rxRequired) {
          currentDiscount = 0
        }

        if (hasProductDiscount) {
          let productPercentDiscount = hasProductDiscount?.percentOff
          currentDiscount =
            (currentDiscount * parseFloat(productPercentDiscount)) / 100
        }
        sum += currentDiscount
      }
    })

    if (!!hasBrandedDiscount) sum += brandedDiscount
    if (!!coupon.percentOff) {
      sum = (sum * parseFloat(coupon.percentOff)) / 100
    }
    if (!!coupon.maxAmount && sum >= parseFloat(coupon.maxAmount)) {
      discountAmount = parseFloat(coupon.maxAmount)
    } else discountAmount = sum
    return parseFloat(discountAmount).toFixed(2)
  }
}

export const checkIfInternalCoupon = (coupon) => {
  const couponType = coupon.slice(0, 2)
  if (couponType === "AC" || couponType === "RM") {
    return true
  } else return false
}
