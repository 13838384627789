import React, { useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import AddMedicineModal from "../Epharmacy/Order/AddMedicineModal"
import MedicinesCardRow from "./MedicinesCard/MedicinesCardRow"
import CollapsibleCard from "../Elements/CollapsibleCard"
import Button from "../Elements/Button"

import styles from "./utils/cart.module.scss"
import { AppContext } from "../../context/AppContext"
import handleInputNumber from "./utils/handleInputNumber"

const MedicinesCard = () => {
  const { dispatch, state } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy

  const PartnerRequestTag = () =>
    epharmacy?.hasPartnerRequest ? (
      <p className="has-text-left mt-1">
        <span className="tag is-primary">Partner Request</span>
      </p>
    ) : null

  const MGWellnessPatientTag = () =>
    epharmacy?.isMedGrocerWellnessPatient ? (
      <p className="has-text-left mt-1">
        <span className="tag is-primary">MedGrocer Wellness Patient</span>
      </p>
    ) : null

  const handleMedicineQtyChange = async (qty, index) => {
    const medicineList = [...medicines]

    if (index?.molecule || index?.productTitle) {
      let value = qty
      value = handleInputNumber({ value, min: 1.0, max: 1000 })
      medicineList.filter((medicine) => {
        if (medicine.productTitle === index.productTitle) {
          medicine.qty = value
        }
      })
      await dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
      await dispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...state,
          epharmacy: { ...state.epharmacy, medicines: [...medicineList] },
        },
      })
    }
  }

  const handleEditMedicine = (index) => {
    if (!index) return
    else {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Edit medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal
              medicine={index}
              isEditing={handleMedicineQtyChange}
              index={index}
            />
          ),
        },
      })
    }
  }

  const handleRedirectToSearch = () => {
    dispatch(
      {
        type: "HIDE_MODAL",
      },
      navigate("/epharmacy/order")
    )
    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT_ONLY",
    })
  }

  return (
    <CollapsibleCard
      title={`Medicines Ordered (${medicines.length})`}
      color="white"
      errorMessage={
        medicines.length === 0 &&
        !epharmacy?.hasPartnerRequest &&
        !epharmacy?.isMedGrocerWellnessPatient && {
          message: "You have no medicines in your list.<br/>",
          onClick: handleRedirectToSearch,
          linkMessage: "Add Medicines",
        }
      }
      disabled={
        state?.documents?.filter((document) => !document?.token)?.length > 0
      }
      isOpen={true}
    >
      <div>
        <MGWellnessPatientTag />
        <PartnerRequestTag />
        <ol className={classNames(styles["cart"], "has-text-left")}>
          {medicines.map((medicine, index) => (
            <MedicinesCardRow
              key={index}
              medicine={medicine}
              index={index}
              handleEditMedicine={handleEditMedicine}
              //handleDeleteMedicine={handleDeleteMedicine}
              handleMedicineQtyChange={handleMedicineQtyChange}
            />
          ))}
        </ol>
        <p className="has-text-left ">
          <Button
            isDisabled={
              state?.documents?.filter((document) => !document?.token)?.length >
              0
            }
            className="is-text has-text-secondary has-text-weight-bold"
            onClick={handleRedirectToSearch}
          >
            + Add more medicines
          </Button>
        </p>
      </div>
    </CollapsibleCard>
  )
}

export default MedicinesCard
