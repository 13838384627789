export const isObjectEmpty = (object) => {
  for (var i in object) return false
  return true
}

export const isBrowser = () => typeof window !== "undefined"

export const formatPrice = (config) => {
  let { priceString } = config
  let priceFloat = parseFloat(priceString)

  return priceFloat.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const clearObjectProperties = (obj) => {
  let tempObj = { ...obj }
  if (typeof obj !== "object" || obj === null) {
    return obj
  }

  for (const prop in obj) {
    if (prop !== "company" && prop !== "fieldsToUpdate") {
      if (typeof obj[prop] === "object") {
        obj[prop] = clearObjectProperties(obj[prop])
      } else {
        obj[prop] = ""
      }
    }
  }

  return obj
}

export const combineObjects = (obj1, obj2) => {
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      obj1[key] = obj2[key]
    }
  }
  return obj1
}

export const toCamelCase = (inputText) => {
  const words = inputText.split(/[\s_]+/)

  // Convert each word to lowercase and capitalize the first letter of each word
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      // Keep the first word lowercase
      return word.toLowerCase()
    } else {
      // Capitalize the first letter of subsequent words
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }
  })

  // Join the words back together without spaces or underscores
  return camelCaseWords.join("")
}
