import React, { useState } from "react"
import classNames from "classnames"
import { Formik, Form } from "formik"

import MedicalSuppliesModal from "../../Elements/MedicalSuppliesModal"
import InputCouponCode from "./InputCoupon/InputCouponCode"
import SelectCoupon from "./InputCoupon/SelectCoupon"

import { getOrderedMedicalSupplies } from "../../../services/getOrderedMedicalSupplies"
import { getCoupon } from "../../Epharmacy/services/coupon"
import styles from "../utils/cart.module.scss"

const InputCoupon = ({ dispatch, state }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSelectedCoupon = (coupon, setErrors) => {
    setIsLoading(true)
    switch (coupon?.value) {
      case "SCPWD":
        // case "WEB100":
        getCoupon(coupon.value, handleSuccessfulCouponCallback, () =>
          handleErrorCouponCallback(setErrors, "selectedCoupon")
        )
        return
      default:
        setIsLoading(false)
        return
    }
  }

  const handleSubmit = (values, { setErrors }) => {
    let parsedCouponCode = values?.couponCode?.toUpperCase()
    setIsLoading(true)
    getCoupon(parsedCouponCode, handleSuccessfulCouponCallback, (error) => {
      handleErrorCouponCallback(setErrors, "couponCode", error)
    })
  }

  const handleSuccessfulCouponCallback = async (coupon) => {
    let medicalSupplies = getOrderedMedicalSupplies({
      medicines: state?.epharmacy?.medicines,
    })

    if (
      medicalSupplies?.length > 0 &&
      coupon?.websiteDescription?.includes(
        "Free quarterly coupons for MedGrocer.com"
      )
    ) {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          isCard: true,
          isBodyOnly: true,
          hideCloseButton: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: <MedicalSuppliesModal dispatch={dispatch} />,
        },
      })
    } else {
      if (coupon.code === "WEB100")
        coupon = { ...coupon, code: "Php 100 Off Convenience Fee" }
      await dispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...state,
          epharmacy: { ...state.epharmacy, coupon: coupon },
        },
      })
    }
    setIsLoading(false)
  }

  const handleErrorCouponCallback = (setErrors, name) => {
    setErrors({ [name]: "Invalid coupon code." })
    setIsLoading(false)
  }

  return (
    <Formik
      initialValues={{ couponCode: "", selectedCoupon: "" }}
      onSubmit={handleSubmit}
    >
      {({ values, setErrors }) => (
        <Form>
          <hr
            className={classNames(
              "has-background-light",
              styles["row__divider"]
            )}
          />
          <div className="px-1 has-text-left">
            <SelectCoupon
              isLoading={isLoading && !values.couponCode}
              values={values}
              setErrors={setErrors}
              handleSelectedCoupon={handleSelectedCoupon}
              state={state}
            />
            <InputCouponCode
              selectedCoupon={values?.selectedCoupon?.value}
              couponCode={values?.couponCode}
              isLoading={isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default InputCoupon
