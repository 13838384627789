import React, { Fragment } from "react"
import { generatePrice } from "../services/computations"
import RxIndicator from "../../Elements/RxIndicator"

const MedicineInfo = ({ medicine }) => (
  <Fragment>
    <h5 className="has-text-primary mt-0">
      {medicine.productTitle}
      {medicine.rxRequired && <RxIndicator />}
    </h5>
    <p className="has-text-left has-text-grey is-size-6">
      Unit Price: Php {generatePrice(medicine)}
    </p>
  </Fragment>
)

export default MedicineInfo
