import React, { useReducer } from "react"

import { initialState, ProofOfPaymentReducer } from "./ProofOfPaymentReducer"

const ProofOfPaymentContext = React.createContext()

const ProofOfPaymentProvider = ({ children }) => {
  let [proofOfPaymentState, proofOfPaymentDispatch] = useReducer(
    ProofOfPaymentReducer,
    {
      ...initialState,
    }
  )

  return (
    <ProofOfPaymentContext.Provider
      value={{ proofOfPaymentState, proofOfPaymentDispatch }}
    >
      {children}
    </ProofOfPaymentContext.Provider>
  )
}

export { ProofOfPaymentContext, ProofOfPaymentProvider }
