import React from "react"

import Button from "./Button"

const MedicalSuppliesModal = ({ dispatch }) => {
  return (
    <div>
      <h3 className="has-text-primary is-size-3 mx-3">
        Medical supplies are not covered by the MedGrocer coupon.
      </h3>
      <p className="is-size-5">
        Please remove all medical supplies from your cart to proceed.
      </p>

      <Button
        size="medium"
        color="secondary"
        className="mt-2 mb-1 bg-secondary"
        onClick={() => {
          dispatch({
            type: "HIDE_MODAL",
          })
        }}
      >
        Okay
      </Button>
    </div>
  )
}

export default MedicalSuppliesModal
